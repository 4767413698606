import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ApiResponse } from '../models/apiResponse'
import { Issue } from '../models/issue'
import { createHttpParams } from '../utils/http-params'
import { ApiOperationTypes } from '../utils/api-operation-types'
import { AuthService } from './auth.service'
import { IssueDepartment } from '../models/issue-department'
import { SocketInterceptorService } from './socket-interceptor.service'
import { createHttpBody } from '../utils/http-body'
import { IssueHasDocument } from '../models/issue-has-document'
import { FileService } from './file.service'
import { IssueHasAccount } from '../models/issue-has-account'
import { IssueState } from '../models/issue-state'
import { IssueComment } from '../models/issue-comment'
import { IssueCommentHasDocument } from '../models/issue-comment-has-document'
import { Document } from '../models/document'
import { Account } from '../models/account'
import { IssueDepartmentHasAccount } from '../models/issue-department-has-account'

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  private issueDepartments!: IssueDepartment[]
  private issueStates!: IssueState[]

  private url = `${environment.url}/issues/`
  private issuesDepartmentsUrl = `${environment.url}/issue-department/`
  private issueHasDocumentUrl = `${environment.url}/issue-has-document/`
  private issueHasAccountUrl = `${environment.url}/issue-has-account/`
  private issueStateUrl = `${environment.url}/issue-state/`
  private issueCommentsUrl = `${environment.url}/issue-comments/`
  private issueCommentHasDocumentUrl = `${environment.url}/issue-comment-has-document/`
  private issueDepartmentHasAccountUrl = `${environment.url}/issue-department-has-account/`

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private socketInterceptor: SocketInterceptorService,
    private fileService: FileService
  ) { }

  getMySubmittedTickets(
    pageNumber: number,
    pageSize: number,
    issue_state_id?: number,
    issue_department_id?: number
  ): Promise<ApiResponse<Issue>> {
    const socketParams = createHttpBody({
      page: pageNumber.toString(),
      size: pageSize.toString(),
      operation: ApiOperationTypes.AND,
      associatedAttributes: 1,
      issue_state_id: issue_state_id,
      issue_department_id: issue_department_id
    })
    return this.socketInterceptor.makeCustomRequest(
      'issues',
      'getMySubmittedIssues',
      undefined,
      socketParams
    )
    // return firstValueFrom(this.http.get<ApiResponse<Issue>>(this.url))
  }

  getIssueById(issueId: number): Promise<Issue> {
    return firstValueFrom(this.http.get<Issue>(`${this.url}${issueId}`))
  }

  getMyAssignedTickets(
    pageNumber: number,
    pageSize: number,
    issue_state_id?: number,
    issue_department_id?: number
  ): Promise<ApiResponse<Issue>> {
    const socketParams = createHttpBody({
      page: pageNumber.toString(),
      size: pageSize.toString(),
      operation: ApiOperationTypes.AND,
      associatedAttributes: 1,
      issue_state_id: issue_state_id,
      issue_department_id: issue_department_id
    })
    return this.socketInterceptor.makeCustomRequest(
      'issues',
      'getMyAssignedIssues',
      undefined,
      socketParams
    )
    // return firstValueFrom(this.http.get<ApiResponse<Issue>>(this.url))
  }

  updateHasUnseenValue(issueId: number, accountId: number, seen: number): Promise<IssueHasAccount> {
    return this.socketInterceptor.makeCustomRequest(
      'issue-has-account',
      'updateSeenValue',
      createHttpBody({
        issue_id: issueId,
        account_id: accountId,
        has_unseen_messages: seen
      })
    )
  }

  removeIssueHasAccount(issueId: number, accountId: number): Promise<IssueHasAccount> {
    const httpParams = createHttpParams({
      issue_id: issueId,
      account_id: accountId
    })

    return firstValueFrom(
      this.http.delete<IssueHasAccount>(`${this.issueHasAccountUrl}`, { params: httpParams })
    )
  }

  addAccountToIssue(issueId: number, accountId: number): Promise<IssueHasAccount> {
    return firstValueFrom(
      this.http.post<IssueHasAccount>(`${this.issueHasAccountUrl}`, {
        issue_id: issueId,
        account_id: accountId
      })
    )
  }

  updateIssueDepartment(issueId: number, issue_department_id: number): Promise<Issue> {
    const body = createHttpBody({
      issue_department_id: issue_department_id,
      issue_id: issueId
    })

    return this.socketInterceptor.makeCustomRequest('issues', 'updateIssueDepartment', body)
  }

  getAllTickets(
    pageNumber: number,
    pageSize: number,
    issue_state_id?: number,
    issue_department_id?: number
  ): Promise<ApiResponse<Issue>> {
    const socketParams = createHttpBody({
      page: pageNumber.toString(),
      size: pageSize.toString(),
      operation: ApiOperationTypes.AND,
      associatedAttributes: 1,
      issue_state_id: issue_state_id,
      issue_department_id: issue_department_id
    })
    return this.socketInterceptor.makeCustomRequest(
      'issues',
      'getAllIssues',
      undefined,
      socketParams
    )
    // return firstValueFrom(this.http.get<ApiResponse<Issue>>(this.url))
  }

  async getIssueDepartments(): Promise<IssueDepartment[]> {
    if (this.issueDepartments) {
      return Promise.resolve(this.issueDepartments)
    }

    const response = await firstValueFrom(
      this.http.get<ApiResponse<IssueDepartment>>(this.issuesDepartmentsUrl)
    )
    this.issueDepartments = response.rows
    return this.issueDepartments
  }

  async getIssueDepartmentsWithAccountAccess(): Promise<IssueDepartmentHasAccount[]> {
    const response = await firstValueFrom(
      this.http.get<IssueDepartmentHasAccount[]>(this.issueDepartmentHasAccountUrl)
    )
    return response
  }

  async getIssueStates(): Promise<IssueState[]> {
    if (this.issueStates) {
      return Promise.resolve(this.issueStates)
    }

    const response = await firstValueFrom(
      this.http.get<ApiResponse<IssueState>>(this.issueStateUrl)
    )
    this.issueStates = response.rows
    return this.issueStates
  }

  async addTicketAttachments(issueId: number, files: File[]): Promise<IssueHasDocument[]> {
    const addedDocs: IssueHasDocument[] = await Promise.all(
      files.map(async file => {
        return new Promise(async (resolve, reject): Promise<void> => {
          const addedFile = await this.fileService.uploadFile(file, file.name)
          const issueHasDocument = await firstValueFrom(
            this.http.post<IssueHasDocument>(this.issueHasDocumentUrl, {
              issue_id: issueId,
              document_id: addedFile.documentId
            })
          )
          resolve(issueHasDocument)
        })
        // const addedFile = await this.fileService.uploadFile(file, file.name)
        // const issueHasDocument = await firstValueFrom(
        //   this.http.post<IssueHasDocument>(this.issueHasDocumentUrl, {
        //     issue_id: issueId,
        //     document_id: addedFile.documentId
        //   })
        // )
        // return issueHasDocument
      })
    )

    return addedDocs
  }

  getIssueComments(issueId: number): Promise<ApiResponse<IssueComment>> {
    return firstValueFrom(
      this.http.get<ApiResponse<IssueComment>>(
        `${this.issueCommentsUrl}?issue_id=${issueId}&createdAt=ASC`
      )
    )
  }

  async addNewComment(
    message: string,
    account_id: number,
    issue_id: number,
    attachedFiles?: File[]
  ): Promise<IssueComment> {
    const addedComment = await firstValueFrom(
      this.http.post<IssueComment>(this.issueCommentsUrl, { message, account_id, issue_id })
    )

    const uploadedDocs: Document[] = []

    if (attachedFiles && attachedFiles.length > 0) {
      attachedFiles.forEach(async attachedFile => {
        const uploadedAttachedFile = await this.fileService.uploadFile(
          attachedFile,
          attachedFile.name
        )

        uploadedDocs.push({
          path: uploadedAttachedFile.path,
          id: uploadedAttachedFile.documentId
        } as Document)

        await firstValueFrom(
          this.http.post<IssueCommentHasDocument>(this.issueCommentHasDocumentUrl, {
            issue_comment_id: addedComment.id,
            document_id: uploadedAttachedFile.documentId
          })
        )
      })
    }

    addedComment.document_id_document_issue_comment_has_documents = uploadedDocs

    return addedComment
  }

  updateTicketState(issueId: number, issue_state_id: number, closed_by?: number): Promise<Issue> {
    return firstValueFrom(
      this.http.put<Issue>(`${this.url}${issueId}`, { issue_state_id, closed_by })
    )
  }

  addTicket(
    created_by: number,
    issue_state_id: number,
    issue_department_id: number,
    store_id: number,
    title: string,
    description: string,
    ticketAddedDocsIds: number[],
    selectedUsers: Account[]
  ): Promise<Issue> {
    const body = createHttpBody({
      title: title,
      description: description,
      store_id: store_id,
      created_by: created_by,
      issue_state_id: issue_state_id,
      issue_department_id: issue_department_id,
      issue_documents: ticketAddedDocsIds,
      issue_accounts: selectedUsers.map(user => user.id)
    })

    // return this.socketInterceptor.makeCustomRequest('issues', 'createNewIssue', body, undefined)

    return firstValueFrom(this.http.post<Issue>(`${this.url}`, body))
  }

  async addTicketViaSockets(
    created_by: number,
    issue_state_id: number,
    issue_department_id: number,
    store_id: number,
    title: string,
    description: string
  ): Promise<Issue> {
    const params = createHttpBody({
      title: title,
      description: description,
      store_id: store_id,
      created_by: created_by,
      issue_state_id: issue_state_id,
      issue_department_id: issue_department_id
    })

    return this.socketInterceptor.makeCreateRequest('issues', params)
  }

  updateIssue(
    report_id: number,
    status_id?: number,
    doctor_id?: number,
    tech_id?: number
  ): Promise<Issue> {
    return firstValueFrom(
      this.http.put<Issue>(`${this.url}${report_id}`, { status_id, doctor_id, tech_id })
    )
  }
}
